import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export default {
  showIfRequired() {
    let parentDomain = window.location.hostname;

    const fragments = parentDomain.split(".");
    if (fragments.length > 1) {
      fragments.shift();
      parentDomain = fragments.join(".");
    }

    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom right",
          equalWeightButtons: true,
          flipButtons: true,
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          readOnly: true,
        },
        analytics: {},
      },
      cookie: {
        name: "cookie_preference",
        domain: parentDomain,
      },
      language: {
        default: "en",
        autoDetect: "browser",
        translations: {
          en: {
            consentModal: {
              title: "Our Cookies",
              description:
                'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon your approval. <a aria-label="Cookie policy" class="cc-link" href="https://www.firstbluehealthcare.co.uk/privacy-policy">Our privacy policy</a>',
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              showPreferencesBtn: "Manage preferences",
              footer:
                '<a href="https://www.firstbluehealthcare.co.uk/privacy-policy">Privacy Policy</a>',
            },
            preferencesModal: {
              title: "Cookie Preferences",
              acceptAllBtn: "Accept all",
              acceptNecessaryBtn: "Reject all",
              savePreferencesBtn: "Save preferences",
              closeIconLabel: "Close modal",
              serviceCounterLabel: "Service|Services",
              sections: [
                {
                  title: "Cookie Usage",
                  description:
                    "We use cookies, which are small text files, to improve your experience on our website and to show you personalised content. You can accept all or manage them individually.",
                },
                {
                  title:
                    'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                  description:
                    "These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly.",
                  linkedCategory: "necessary",
                },
                {
                  title: "Analytics Cookies",
                  description:
                    "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you.",
                  linkedCategory: "analytics",
                  cookieTable: {
                    headers: {
                      name: "Name",
                      domain: "Domain",
                      expiration: "Expiration",
                      description: "Description",
                    },
                    body: [
                      {
                        name: "_ga",
                        domain: "google.com",
                        expiration: "2 years",
                        description: "Used to distinguish users.",
                      },
                      {
                        name: "_gid",
                        domain: "google.com",
                        expiration: "1 day",
                        description: "Used to distinguish users.",
                      },
                      {
                        name: "_ga_<id>",
                        domain: "google.com",
                        expiration: "2 years",
                        description: "Used to persist session state.",
                      },
                      {
                        name: "_gac_gb_<id>",
                        domain: "google.com",
                        expiration: "90 days",
                        description: "Campaign related information.",
                      },
                    ],
                  },
                },
                {
                  title: "More Information",
                  description:
                    'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="https://www.firstbluehealthcare.co.uk/contact-us">contact us</a>.',
                },
              ],
            },
          },
        },
      },
    });
  },
};
