enum MODE {
  APP = "app",
  SMS = "sms",
  RECOVERY = "recovery",
}

export default (challenge, method) => ({
  method: method,
  smsSentAt: null,
  promptSmsResend: false,

  init() {
    if (this.method === "sms") {
      this.sendSms();
    }

    this.$watch("method", (value) => {
      if (value === "sms") {
        this.sendSms();
      }
    });

    setInterval(() => {
      if (this.smsSentAt) {
        const now = new Date();
        const sentSecondsAgo =
          (now.getTime() - this.smsSentAt.getTime()) / 1000;
        this.promptSmsResend = sentSecondsAgo > 20;
      }
    }, 1000);

    if (challenge) {
      setTimeout(
        () => {
          // If we have a challenge code and it isn't completed within 3 minutes it will
          // no longer work anyway. So refresh the page and ask them to login again
          location.reload();
        },
        3 * 60 * 1000,
      );
    }
  },

  get isChooseMode() {
    return !this.method;
  },

  get isAppMode() {
    return this.method === MODE.APP;
  },

  get isSmsMode() {
    return this.method === MODE.SMS;
  },

  get isRecoveryMode() {
    return this.method === MODE.RECOVERY;
  },

  setMode(mode: MODE | null) {
    this.method = mode;

    if (this.method && this.$refs.input) {
      this.$focus.focus(this.$refs.input);
    }
  },

  sendSms() {
    const csrf = document.querySelector('[name="csrf-token"]');
    this.smsSentAt = new Date();

    fetch(`/otp/sms?challenge=${challenge}`, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-CSRF-Token": csrf ? csrf.getAttribute("content") || "" : "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data.sent === true) {
          alert(
            "An error occured when sending your SMS verification code. Please try again shortly or contact support if this issue persists.",
          );
        }
      });
  },
});
